@font-face {
  font-family: "sales-font";
  src: url(../fonts/Gilroy-Medium.ttf);
  font-weight: 400;
}
@font-face {
  font-family: "sales-font";
  src: url(../fonts/Gilroy-SemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: "sales-font";
  src: url(../fonts/Gilroy-Bold.ttf);
  font-weight: 700;
}
