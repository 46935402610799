.row {
  display: flex;
  flex-wrap: wrap;
}
.row > * {
  min-width: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.btn-link {
  @apply underline text-primary;
}
/* .table > thead {
  @apply text-dark-gray;
}
.table > thead th {
  @apply font-normal;
}
.table tbody tr {
  @apply even:bg-primary/10 hover:bg-primary/20 transition-colors;
} */
.table tbody th,
.table tbody td {
  padding: 1rem;
  @apply last:text-right;
}
.table thead th,
.table thead td {
  padding: 0.7rem 1rem;
}
.table th,
.table td {
  @apply last:text-right;
}
.table thead tr {
  @apply bg-gray-50 rounded text-secondary;
}
.table thead tr > * {
  @apply first:rounded-l last:rounded-r;
}
.table tbody tr {
  @apply border-b border-dashed border-gray-100 last:border-transparent;
}
.input-group-text {
  display: inline-flex;
  align-items: center;
  color: #5e6278;
  @apply px-2;
  /* first:border-r last:border-l border-light */
}
