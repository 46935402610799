input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
* {
  font-family: "sales-font";
  -webkit-tap-highlight-color: transparent;
  @apply selection:bg-success selection:text-white;
}
body {
  @apply bg-[#f5f8f9] text-dark;
}
body:has(.drawer.active, .modal.active, .dropdown[data-active="true"]) {
  overflow: hidden;
}
button {
  white-space: nowrap;
}
button:disabled {
  cursor: not-allowed;
  opacity: 0.75;
}
input[type="checkbox"] {
  width: 1.25rem;
  border-radius: 0.25rem;
  aspect-ratio: 1/1;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  @apply bg-sky-200 bg-gradient-to-br from-sky-900 via-sky-400 to-sky-50;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex > *,
.flex-center > * {
  min-width: 0;
}
.inset-0 {
  @apply w-full h-full;
}
.date-picker .rmdp-calendar,
.date-picker .rmdp-day-picker,
.date-picker .rmdp-day-picker > div {
  width: 100%;
}
.date-picker .rmdp-day-picker > div {
  @apply space-y-2;
}
.date-picker .rmdp-day {
  @apply w-10 h-10;
}
.date-picker .rmdp-day.rmdp-selected > span {
  @apply bg-primary;
}
.date-picker .rmdp-day.rmdp-today > span {
  @apply bg-dark;
}
.date-picker .rmdp-day > span {
  @apply text-lg rounded transition-colors;
}
.date-picker .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  @apply hover:bg-blue-500;
}
.date-picker .rmdp-day.rmdp-deactive,
.date-picker .rmdp-day.rmdp-disabled {
  @apply text-[#B3B3B3];
}
.date-picker .rmdp-arrow-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.date-picker .rmdp-arrow {
  margin: 0;
}
.date-picker .rmdp-header > div {
  align-items: center;
  gap: 2rem;
  @apply text-primary;
}
.date-picker .rmdp-header-values > span {
  @apply underline text-primary text-lg;
}
