@import "./_font.css";
@import "./_components.css";
@import "./_custom.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  html {
    font-size: 16px;
    line-height: normal;
  }
  [hidden] {
    display: none !important;
  }
  h1,
  .h1 {
    @apply text-4xl;
  }
  h2,
  .h2 {
    @apply text-3xl;
  }
  h3,
  .h3 {
    @apply text-2xl;
  }
  h4,
  .h4 {
    @apply text-xl;
  }
  h5,
  .h5 {
    @apply text-lg;
  }
  h6,
  .h6 {
    @apply text-base;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    line-height: normal;
    margin-bottom: 0;
    @apply font-semibold;
  }
  hr {
    width: 100%;
    height: 1px;
    border: none;
    opacity: 1;
  }
  input,
  textarea {
    border: none;
    background-color: transparent;
    @apply focus:outline-none;
  }
}
.btn-sm {
  padding: 0.45rem 0.65rem;
  @apply text-sm;
}
